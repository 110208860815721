<template>
  <div class="dish-day" :dataType="type" :class="{ animated: counterFlag }">
    <div class="header">
      <div class="day s-text-caption">
        {{ day?.name }} <span class="day-date">{{ dayDate }}</span>
      </div>
      <div v-if="day?.price" class="price s-text-caption">{{ day?.price }} ₴</div>
      <div v-if="!day?.price && day.date != dateNow" @click="closeMobile2" class="add-dish s-text-caption">
        {{ DISHES_TRANSLATE?.orders?.btn?.add_dish }}
      </div>
      <div v-if="!day?.price && day?.date == dateNow" @click="closeMobile2" class="add-dish s-text-caption">
        {{ DISHES_TRANSLATE?.orders?.no_orders }}
      </div>
    </div>
    <div class="dish-status">
      <div class="already-preparing" v-if="!day?.options?.timer && day?.options?.status != '' && Object.keys(day?.items).length > 0">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M4.33594 8.33333L7.66927 11.6667L14.3359 5" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M7.66667 8.33333L11 5M1 8.33333L4.33333 11.6667L1 8.33333Z" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="s-text-caption">{{ day?.options?.status }}</span>
      </div>
      <div v-else-if="day?.options?.timer" class="order-confirmation">
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M8 5V8.125L10 10" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
        <span class="s-text-caption"> {{ timer }} {{ day?.options?.status }}</span>
      </div>
    </div>
    <transition-group name="fade">
      <template v-for="item in day.items" :key="item.id">
        <div class="dish-cell" :class="{ solo: item.qty == 1, multiple: item.qty > 1 }">
          <div class="dish-cell-top">
            <img v-if="item?.product?.image" :src="item?.product?.image?.url" alt="dish-min" />
            <div class="cell-text m-text-des">
              <span :title="item?.product?.name" class="cell-title">{{ item?.product?.name }} </span>
              <template v-if="item?.product?.modifiers?.applied">
                <div v-if="togglerBtnAdd(item)" class="modifier-list">
                  <template v-if="item?.product?.modifiers?.applied?.remove.length">
                    <div v-for="item in appliedIngredients(item?.product?.modifiers)" :key="item?.id" class="modifier">
                      {{ DISHES_TRANSLATE?.dish?.modifiers?.remove }} <span class="lowercase">{{ item?.name }}</span>
                    </div>
                  </template>
                  <template v-if="item?.product?.modifiers?.applied?.add.length">
                    <div v-for="item in appliedModifiers(item?.product?.modifiers)" :key="item?.id" class="modifier">{{ item?.name }} {{ item?.price ? `+${item?.price}₴` : '' }}</div>
                  </template>
                </div>
                <button v-if="togglerBtnAdd(item)" @click="modifierToggle" class="show-more">
                  <!-- <span class="toggle-show">Еще</span>
                  <span class="toggle-hide">Скрыть</span> -->
                  <svg class="angle" width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L4.5 5L8 1" stroke="#000E29" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                </button>
              </template>
              <div class="count-price s-text-caption">
                {{ item?.qty }} х {{ item?.product?.price }} ₴
                <div v-if="(day?.options?.timer && day?.options?.status != '') || (!day?.options?.timer && day?.options?.status == '')" @click="removeDishFromDay(item, day)" class="remove-dish-btn">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4.30078 12.5212L12.0754 4.74658M4.30078 4.74658L12.0754 12.5212" stroke="#000E29" stroke-opacity="0.4" stroke-width="1.45775" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <div v-if="item?.link" @click="showPayment(item)" class="surcharge-wrapper">
                  <span v-if="item.is_surcharge && item.complete_surcharge" class="surcharge">Доплата {{ item.complete_surcharge }} ₴</span>
                  <span v-if="item.is_surcharge && item.wait_surcharge" class="surcharge is_link">Очікується доплата {{ item.wait_surcharge }} ₴</span>
                </div>
                <div v-else class="surcharge-wrapper">
                  <span v-if="item.is_surcharge && item.complete_surcharge" class="surcharge">Доплата {{ item.complete_surcharge }} ₴</span>
                  <span v-if="item.is_surcharge && item.wait_surcharge" class="surcharge">Очікується доплата {{ item.wait_surcharge }} ₴</span>
                </div>
              </div>
            </div>
          </div>
          <div v-if="item?.timer && timerShow" class="dish-cell-timer">
            {{ DISHES_TRANSLATE?.modals?.orders?.exceed?.timer }}
            {{ surchargeTimer }}
          </div>
        </div>
      </template>
    </transition-group>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {
    already: {
      type: Boolean
    },
    confirm: {
      type: String
    },
    day: {
      type: Object
    },
    type: {
      type: String
    }
  },
  data() {
    return {
      counterFlag: false,
      surchargeTimer: "",
      removeDishFlag: true,
      diff: null,
      timerShow: true,
      timer: "00:00",
      interval: null,
      intervalFlag: true,
      selectUserSave: null
    };
  },
  computed: {
    ...mapGetters(["DISHES_TRANSLATE", "SELECT_USER", "ORDERS"]),
    dayDate() {
      let day = JSON.parse(JSON.stringify(this.day));
      let date = day?.date
        ?.slice(5, 10)
        .split("-")
        .reverse()
        .join(".");
      return date;
    },
    dateNow() {
      let now = new Date();
      let day = now.getDay();
      let dayIncrement = 0;

      if (day == 6) {
        dayIncrement = 2;
      } else if (day == 0) {
        dayIncrement = 1;
      }

      let year = now.getFullYear();
      let month = now.getMonth() < 10 ? "0" + (now.getMonth() + 1) : now.getMonth() + 1;
      let date = now.getDate() < 10 ? "0" + (now.getDate() + dayIncrement) : now.getDate() + dayIncrement;
      let formatDate = `${year}-${month}-${date}`;
      return formatDate;
    }
  },
  methods: {
    ...mapActions(["CheckTimer", "GetOrders", "DeleteOrders"]),
    togglerBtnAdd(item) {
      return item?.product?.modifiers?.applied?.remove.length || item?.product?.modifiers?.applied?.add.length;
    },
    togglerShow() {
      const buttons = this.$el.querySelectorAll(".show-more");
      if (buttons) {
        buttons.forEach(btn => {
          const target = btn.closest("div").querySelector(".modifier-list");
          const height = parseInt(target.style.maxHeight);
          if (height > 56) {
            btn.style.display = "block";
            btn.closest(".cell-text").classList.add("indent");
          }
        });
      }
    },
    appliedIngredients(modifier) {
      const applied = modifier?.applied?.remove;
      const ingredients = modifier?.default?.ingredients;
      let newArr = [];
      applied.forEach(apply => {
        const res = ingredients.filter(item => item?.id == apply);
        if (res.length) {
          newArr.push(res[0]);
        }
      });
      return newArr;
    },
    appliedModifiers(modifier) {
      const applied = modifier?.applied?.add;
      const modifiers = modifier?.default?.modifiers;
      let newArr = [];
      applied.forEach(apply => {
        const res = modifiers.filter(item => item?.id == apply);
        if (res.length) {
          newArr.push(res[0]);
        }
      });
      return newArr;
    },
    modifierSizeFunc() {
      const modifierList = this.$el.querySelectorAll(".modifier-list");
      if (modifierList) {
        modifierList.forEach(item => {
          item.classList.remove("hidden");
          item.classList.remove("mounted");
          item.removeAttribute("style");
          setTimeout(() => {
            const height = item.clientHeight;
            item.style.maxHeight = `${height}px`;
            item.classList.add("hidden");
            item.classList.add("mounted");
          }, 1);
        });
      }
    },
    modifierToggle(event) {
      const button = event.target.closest(".show-more");
      const target = event.target.closest(".cell-text").querySelector(".modifier-list");
      button.classList.toggle("active");
      target.classList.toggle("hidden");
    },
    closeMobile() {
      this.$emit("closeMobile");
    },
    closeMobile2() {
      this.$emit("closeMobile2");
    },
    async removeDishFromDay(dish) {
      if (this.removeDishFlag) {
        this.removeDishFlag = false;
        dish.qty == 2 ? (this.counterFlag = true) : (this.counterFlag = false);
        await this.DeleteOrders({
          data: {
            item_id: dish.surcharge_id ? dish.surcharge_id : dish.id,
            order_id: this.day.id
          },
          dish_price: dish.product?.price,
          dish_qty: dish.qty
        });
        this.removeDishFlag = true;
      }
      this.$emit("clearLink", this.day);
    },
    showPayment(item) {
      this.$emit("showPayment", item);
    },
    surchargeTimerFunc() {
      let is_timer = Object.values(this.day.items).filter(item => {
        return item.timer;
      });

      if (is_timer[0]) {
        let t = is_timer[0].timer.split(/[- :]/);
        let d = new Date(t[0], t[1] - 1, t[2], t[3], t[4]);
        let timer = new Date(d);
        timer.setSeconds(timer.getSeconds() + 30);
        let minutes, seconds;

        let timerInterval = setInterval(() => {
          let now = new Date();
          this.diff = timer - now;

          if (this.diff <= 0 || !this.timerShow) {
            clearInterval(timerInterval);
            minutes = "00";
            seconds = "00";
            this.CheckTimer({
              data: {
                timer_id: is_timer[0].timer_id
              }
            });
            this.timerShow = false;
          } else {
            minutes = Math.floor((this.diff / 1000 / 60) % 60);
            seconds = Math.floor((this.diff / 1000) % 60);
          }

          minutes < 10 ? (minutes = "0" + minutes) : "";
          seconds < 10 ? (seconds = "0" + seconds) : "";

          this.surchargeTimer = `${minutes}:${seconds}`;
        }, 1000);
      }
    },
    checkTimer() {
      let checkTimerInterval = setInterval(() => {
        let is_timer = Object.values(this.day.items).filter(item => {
          return item.timer;
        });

        if (is_timer[0] && this.timerShow) {
          this.CheckTimer({
            data: {
              timer_id: is_timer[0].timer_id
            }
          });
        } else {
          clearInterval(checkTimerInterval);
          this.timerShow = false;
        }
      }, 30000);
    },
    timerToEnd() {
      let time = this.day?.options?.time_end;
      if (time) {
        let t = time.split(/[- :]/);
        let d = new Date(t[0], t[1] - 1, t[2], t[3], t[4]);
        time = new Date(d);
        let countDownDate = new Date(time).getTime();

        let timerFunct = () => {
          let now = new Date().getTime();
          let distance = countDownDate - now;
          let days = Math.floor((distance % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24));
          let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
          let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          hours += days * 24;
          hours < 10 ? (hours = "0" + hours) : "";
          minutes < 10 ? (minutes = "0" + minutes) : "";
          this.timer = `${hours}:${minutes}`;
          if (distance <= 0) {
            clearInterval(this.interval);
            this.intervalFlag = false;
            this.timer = "00:01";
            this.GetOrders();
          }
        };
        timerFunct();
        this.interval = setInterval(timerFunct, 1000);
      }
    }
  },
  mounted() {
    this.modifierSizeFunc();
    this.timerToEnd();
    this.surchargeTimerFunc();
    this.checkTimer();
    this.togglerShow();
  },
  async updated() {
    if (this.intervalFlag) this.timerToEnd();
    this.modifierSizeFunc();
    setTimeout(() => {
      this.togglerShow();
    }, 301);
  },
  beforeUnmount() {
    clearInterval(this.interval);
  },
  watch: {
    ORDERS() {
      setTimeout(() => {
        this.selectUserSave = this.SELECT_USER;
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.indent {
  position: relative;
  padding-right: 28px;
}
.show-more {
  position: absolute;
  top: 23px;
  right: 0;
  display: none;
  padding: 0 8px;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: var(--bg-main);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.25s ease;
  // .angle {
  //   margin-left: 5px;
  // }
  svg path {
    transition: stroke 0.25s ease;
  }
  .toggle-hide,
  .toggle-show {
    transition: color 0.25s ease;
  }
  &:hover {
    .toggle-show,
    .toggle-hide {
      color: var(--bg-white);
    }
    background: var(--blue-main);
    svg path {
      stroke: #fff;
    }
  }
  .toggle-hide {
    display: none;
  }
  &.active {
    .toggle-show {
      display: none;
    }
    .toggle-hide {
      display: initial;
    }
    svg {
      transform: scale(-1);
      margin-bottom: 1px;
    }
  }
}
.modifier-list {
  margin-left: -8px;
  overflow: hidden;
  transition: max-height 0.5s ease;
  &:not(.mounted) {
    visibility: hidden;
    position: absolute;
    left: 0;
    width: calc(100% - 29px);
  }
  &.hidden {
    max-height: 56px !important;
  }
}
.modifier {
  margin-bottom: 4px;
  display: inline-block;
  padding: 0 8px;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  background: var(--bg-main);
  border: none;
  border-radius: 8px;
  &:not(:last-child) {
    margin-right: 4px;
  }
  &.active {
    color: var(--bg-white);
    background: var(--blue-main);
  }
  .cross {
    margin-left: 8px;
  }
}
.lowercase {
  text-transform: lowercase;
}
.dish-day {
  background: var(--white-100);
  padding: 20px;
  border-radius: 10px;
  width: 355px;
  margin: 2px 0;

  &:nth-child(n + 3) {
    .already-preparing {
      display: none;
    }
  }
}
.day-date {
  color: var(--dark-40);
}
.dish-min {
  width: 100%;
  max-width: 56px;
  max-height: 56px;
}
.header {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.add-dish,
.price,
.day {
  text-transform: uppercase;
}
.add-dish {
  cursor: default;
  color: var(--dark-40);
}
.order-confirmation,
.already-preparing {
  cursor: default;
  display: flex;
  align-items: center;
  color: var(--blue-main);
  svg {
    margin-top: -2px;
    margin-right: 4px;
  }
}
.dish-cell {
  padding-top: 12px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .dish-cell-top {
    width: 100%;
    display: flex;
  }

  .dish-cell-timer {
    font-size: 12px;
    color: var(--blue-main);
    text-align: right;
  }

  img {
    width: 56px;
    height: 56px;
  }
}
.cell-title {
  display: block;
  max-width: 247px;
  padding-right: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.cell-text {
  position: relative;
  width: calc(100% - 56px);
  margin-left: 12px;
}
.count-price {
  display: flex;
  align-items: center;
  line-height: 18px;
  color: var(--dark-40);
  .remove-dish-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding-bottom: 0;
    margin-left: 2px;
    position: relative;
    bottom: 1px;
  }
}
.surcharge {
  user-select: none;
  color: var(--blue-main);
  line-height: 17px;

  &.is_link {
    cursor: pointer;
  }
}

.dish-day:not(.animated):not([dataType="slider"]) .fade-enter-active.dish-cell.solo {
  animation: height-in 300ms ease;
  animation-fill-mode: forwards;

  img {
    animation: bounce-in 300ms ease;
    animation-fill-mode: forwards;
  }
  .cell-text {
    animation: text-in 300ms ease;
    animation-fill-mode: forwards;
  }
}

.dish-day:not(.animated):not([dataType="slider"]) .fade-leave-active.dish-cell.solo {
  animation: height-out 300ms ease;
  animation-fill-mode: forwards;

  img {
    animation: bounce-out 300ms ease;
    animation-fill-mode: forwards;
  }
  .cell-text {
    animation: text-out 300ms ease;
    animation-fill-mode: forwards;
  }
}

.dish-day.animated:not([dataType="slider"]) .fade-leave-active.dish-cell {
  display: none;
}

.dish-day:not(.animated)[dataType="slider"] .fade-enter-active.dish-cell.solo {
  animation: height-in 300ms ease;
  animation-fill-mode: forwards;

  img {
    animation: bounce-in 300ms ease;
    animation-fill-mode: forwards;
  }
  .cell-text {
    animation: text-in 300ms ease;
    animation-fill-mode: forwards;
  }
}

.dish-day:not(.animated)[dataType="slider"] .fade-leave-active.dish-cell.solo {
  animation: height-out 300ms ease;
  animation-fill-mode: forwards;

  img {
    animation: bounce-out 300ms ease;
    animation-fill-mode: forwards;
  }
  .cell-text {
    animation: text-out 300ms ease;
    animation-fill-mode: forwards;
  }
}

.dish-day[dataType="slider"] .fade-leave-active.dish-cell {
  display: none;
}

.surcharge-wrapper {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

@keyframes height-in {
  0% {
    padding-top: 0;
    height: 0;
  }

  100% {
    padding-top: 12px;
    height: 56px;
  }
}

@keyframes height-out {
  0% {
    height: 56px;
    padding-top: 12px;
  }

  100% {
    height: 0;
    padding-top: 0;
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes bounce-out {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes text-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes text-out {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
</style>
