<template>
  <div class="btn-preferences s-text-caption">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 4.5C11 5.32843 10.3284 6 9.5 6C8.67157 6 8 5.32843 8 4.5M11 4.5C11 3.67157 10.3284 3 9.5 3C8.67157 3 8 3.67157 8 4.5M11 4.5H14M8 4.5H2M7.5 10C7.5 10.8284 6.82843 11.5 6 11.5C5.17157 11.5 4.5 10.8284 4.5 10M7.5 10C7.5 9.17157 6.82843 8.5 6 8.5C5.17157 8.5 4.5 9.17157 4.5 10M7.5 10H14M4.5 10H2" stroke="#3CAFE9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
.btn-preferences {
  cursor: pointer;
  text-transform: uppercase;
  height: 48px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  background: var(--blue-light);
  svg {
    margin-top: -1px;
    margin-right: 6px;
  }
}
</style>
