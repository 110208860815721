<template>
  <div class="modal-container">
    <div class="scroll">
      <div class="header">
        <img src="@/assets/img/B_logo.svg" alt="logo" class="logo" />
        <div class="header-user s-text-caption">
          <div class="user-name">{{ currentUser?.name }} {{ currentUser?.last_name }}</div>
          <div class="user-limit">
            {{ OTHER_TRANSLATE?.user?.limit?.label }} {{ limit }} ₴
            {{ OTHER_TRANSLATE?.user?.limit?.day }}
          </div>
        </div>
        <div class="close-btn" @click="$emit('update:show', false)">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 19L5 5M19 5L5 19" stroke="currentColor" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </div>
      </div>
      <div class="main-content">
        <div class="left-container">
          <div class="category-dishes s-text-caption">
            {{ activeDish?.category.name }}
            <div class="slider-icon-box" v-for="icon in activeDish?.tags" :key="icon.id">
              <InfoMessage v-if="icon?.is_allergen" bottom="38px" left="-21px" arrowDir="bl">
                {{ icon.tooltip }}
              </InfoMessage>
              <img :src="icon?.icon?.url" alt="icon" :class="{ red: icon?.is_allergen }" />
              <span class="green" :class="{ red: icon?.is_allergen }"> {{ icon?.name }}</span>
            </div>
          </div>
          <h2 class="r-title-2">{{ activeDish?.name }}</h2>
          <div class="kkal-box" v-if="activeDish?.info?.length > 0">
            <div class="part-box">
              <span class="part s-text-alert">{{ DISHES_TRANSLATE?.dish?.portion }}</span>
              <div class="kkal-cell" :class="{ active: index == activeIndexKKal }" @click="switchVariant(index)" v-for="(variant, index) in activeDish?.info" :key="variant.id">{{ finalWeight(variant.gram) }} г</div>
            </div>
            <div class="kkal-details-box">
              <div v-if="activeDish?.info?.[activeIndexKKal]?.kcal > 0" class="kkal-details">
                <span>{{ finalKcal }}</span
                ><span>{{ DISHES_TRANSLATE?.dish?.kcal }}</span>
              </div>
              <div v-if="activeDish?.info?.[activeIndexKKal]?.protein > 0" class="kkal-details">
                <span>{{ finalProtein }}</span
                ><span>{{ DISHES_TRANSLATE?.dish?.protein }}</span>
              </div>
              <div v-if="activeDish?.info?.[activeIndexKKal]?.fats > 0" class="kkal-details">
                <span>{{ finalFats }}</span
                ><span>{{ DISHES_TRANSLATE?.dish?.fats }}</span>
              </div>
              <div v-if="activeDish?.info?.[activeIndexKKal]?.carbohydrates > 0" class="kkal-details">
                <span>{{ finalCarbohydrates }}</span
                ><span>{{ DISHES_TRANSLATE?.dish?.carbohydrates }}</span>
              </div>
            </div>
          </div>
          <div class="dish-description m-text-des">
            <p>
              <span class="modifier-wrapper">{{ activeDish?.composition }}<template v-if="activeDish?.modifiers?.default?.ingredients">,&nbsp;</template></span>
              <template v-if="activeDish?.modifiers?.default?.ingredients">
                <template v-for="(item, index) in activeDish?.modifiers?.default?.ingredients" :key="item?.id">
                  <span class="modifier-wrapper">
                    <button @click="modifierClick(item)" data-attr="ingredient" class="modifier active">{{ item?.name }}</button>
                    <template v-if="index < activeDish?.modifiers?.default?.ingredients.length - 1">&nbsp;,&nbsp;</template>
                    <template v-else>&nbsp;.</template>
                  </span>
                </template>
              </template>
            </p>
            <br />
            <br />
            <template v-if="activeDish?.modifiers?.default?.modifiers">
              <p>
                <span class="modifier-wrapper">{{ DISHES_TRANSLATE?.dish?.modifiers?.add }}:&nbsp;</span>
                <template v-for="(item, index) in activeDish?.modifiers?.default?.modifiers" :key="item?.id">
                  <span class="modifier-wrapper">
                    <button @click="modifierClick(item, true)" data-attr="modifier" class="modifier">{{ item?.name }} {{ item?.price ? `+${item?.price}₴` : '' }}</button>
                    <template v-if="index < activeDish?.modifiers?.default?.modifiers.length - 1">&nbsp;,&nbsp;</template>
                    <template v-else>&nbsp;.</template>
                  </span>
                </template>
              </p>
            </template>
          </div>
          <div class="dish-slider-bottom-wrapper">
            <div class="recommended-dish">
              <div class="recommended-dish-content">
                <img :src="randoDishItem?.image?.url" :alt="randoDishItem?.image?.title" />
                <div>
                  <span class="recommended-dish-text blue">{{ DISHES_TRANSLATE?.dish?.recommended?.label }}</span>
                  <span class="recommended-dish-text">
                    {{ randoDishItem?.name }}
                    {{ DISHES_TRANSLATE?.dish?.recommended?.price }}
                    <span>{{ randoDishItem?.info[0]?.price }} ₴</span>
                  </span>
                </div>
              </div>
              <div class="recommended-dish-open" @click="recommendedOpen()">
                {{ DISHES_TRANSLATE?.dish?.recommended?.btn?.open }}
              </div>
            </div>
            <div class="dish-slider-bottom">
              <div class="total-price r-title-2">
                <span>{{ finalPrice || 0 }} ₴</span>
                <span class="blue">{{ DISHES_TRANSLATE?.dish?.recommended?.day?.choose }}</span>
              </div>
              <div class="days-box">
                <div class="checkbox-label" v-for="(day, index) in orders" :key="day.date">
                  <div class="day-card-info">
                    <DishDaysCard type="slider" :day="day" class="dish-day-slider" confirm="04:24 до подтверждения заказа"></DishDaysCard>
                    <div class="arrow-info"></div>
                  </div>
                  <div @click="addDishToDay(day, activeDish, index, dataModifiers)" class="day s-text-alert" :class="{ active: activeDayBtn(day) }">
                    <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1.33594 4.33333L4.66927 7.66667L11.3359 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    <svg class="plus" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.83203 7.99967H8.4987M8.4987 7.99967H13.1654M8.4987 7.99967V3.33301M8.4987 7.99967V12.6663" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                    {{ day.short_name }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-container">
          <div class="dishes-slider">
            <div class="swiper-container">
              <div class="swiper-wrapper">
                <div v-for="dish in dishesList" :key="dish.id" class="swiper-slide">
                  <img :src="dish?.image?.url" />
                </div>
              </div>
              <div v-if="dishesList.length > 1" class="swiper-button-next">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
                  <path d="M38 20L2.00001 20M24 6L38 20L24 6ZM24 34L38 20L24 34Z"></path>
                </svg>
              </div>
              <div v-if="dishesList.length > 1" class="swiper-button-prev">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" width="40" height="40">
                  <path d="M38 20L2.00001 20M24 6L38 20L24 6ZM24 34L38 20L24 34Z"></path>
                </svg>
              </div>
              <div v-if="dishesList.length > 1" class="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="dish-slider-bottom fixed">
    <div class="total-price r-title-2">
      <span>{{ finalPrice || 0 }} ₴</span>
      <span class="blue">{{ DISHES_TRANSLATE?.dish?.recommended?.day?.choose }}</span>
    </div>
    <div class="days-box">
      <div class="checkbox-label" v-for="(day, index) in orders" :key="day.date">
        <div class="day-card-info">
          <DishDaysCard type="slider" :day="day" class="dish-day-slider" confirm="04:24 до подтверждения заказа"></DishDaysCard>
          <div class="arrow-info"></div>
        </div>
        <div @click="addDishToDay(day, activeDish, index, dataModifiers)" class="day s-text-alert" :class="{ active: activeDayBtn(day) }">
          <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.33594 4.33333L4.66927 7.66667L11.3359 1" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <svg class="plus" width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.83203 7.99967H8.4987M8.4987 7.99967H13.1654M8.4987 7.99967V3.33301M8.4987 7.99967V12.6663" stroke="white" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          {{ day.short_name }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import SwiperCore, { Navigation, Pagination } from "swiper/core";
SwiperCore.use([Navigation, Pagination]);
import InfoMessage from "@/components/parts/info-message";
import DishDaysCard from "@/components/dish-days-card";
import { mapActions, mapGetters } from "vuex";
import "swiper/swiper-bundle.css";
export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    activeIndex: {
      type: Number,
      default: 0
    },
    options: {
      type: Object
    },
    dishes: {
      type: Array
    },
    dishesList: {
      type: Array
    },
    currentUser: {
      type: Object
    },
    recommendedDishes: {
      type: Array
    }
  },
  components: {
    InfoMessage,
    DishDaysCard
  },
  data() {
    return {
      modifKcal: 0,
      modifProtein: 0,
      modifFats: 0,
      modifCarbohydrates: 0,
      modifPriceTotal: 0,
      modifWeight: 0,
      dataModifiers: {
        add: [],
        remove: []
      },
      randoDishItem: null,
      activeIndexKKal: 0,
      addDishToDayFlag: false,
      activeDishIndex: this.activeIndex,
      swiper: null,
      days: [
        { title: "Пн", status: true },
        { title: "Вт", status: false },
        { title: "Ср", status: false },
        { title: "Чт", status: true },
        { title: "Пт", status: false }
      ]
    };
  },
  computed: {
    ...mapGetters(["OTHER_TRANSLATE", "ORDERS", "DISHES_TRANSLATE"]),
    limit() {
      return this.currentUser.limit ? this.currentUser.limit.sum : "0";
    },
    activeDish() {
      if (this.dishesList) {
        return this.dishesList[this.activeDishIndex];
      } else {
        return null;
      }
    },
    finalPrice() {
      if (this.dishesList) {
        const newPrice = parseFloat(this.dishesList[this.activeDishIndex]?.info?.[this.activeIndexKKal]?.price) + this.modifPriceTotal;
        return newPrice < 0 ? 0 : newPrice;
      } else {
        return 0;
      }
    },
    finalKcal() {
      if (this.dishesList) {
        const newKcal = parseFloat(this.dishesList[this.activeDishIndex]?.info?.[this.activeIndexKKal]?.kcal) + this.modifKcal;
        return newKcal < 0 ? 0 : newKcal;
      } else {
        return 0;
      }
    },
    finalProtein() {
      if (this.dishesList) {
        const newProtein = parseFloat(this.dishesList[this.activeDishIndex]?.info?.[this.activeIndexKKal]?.protein) + this.modifProtein;
        return newProtein < 0 ? 0 : newProtein;
      } else {
        return 0;
      }
    },
    finalFats() {
      if (this.dishesList) {
        const newFats = parseFloat(this.dishesList[this.activeDishIndex]?.info?.[this.activeIndexKKal]?.fats) + this.modifFats;
        return newFats < 0 ? 0 : newFats;
      } else {
        return 0;
      }
    },
    finalCarbohydrates() {
      if (this.dishesList) {
        const newCarbohydrates = parseFloat(this.dishesList[this.activeDishIndex]?.info?.[this.activeIndexKKal]?.carbohydrates) + this.modifCarbohydrates;
        return newCarbohydrates < 0 ? 0 : newCarbohydrates;
      } else {
        return 0;
      }
    },
    orders() {
      let clon = JSON.parse(JSON.stringify(this.ORDERS));
      clon?.shift();
      return clon;
    }
  },
  methods: {
    ...mapActions(["PostOrders", "GetOrders"]),
    finalWeight(weight) {
      const newWeight = parseFloat(weight) + this.modifWeight;
      return newWeight < 0 ? 0 : newWeight;
    },
    modifierClick(item, modifier) {
      const target = event.target;
      const price = parseFloat(item?.price);
      const kcal = parseFloat(item?.kcal);
      const proteint = parseFloat(item?.protein);
      const fats = parseFloat(item?.fats);
      const carbohydrates = parseFloat(item?.carbohydrates);
      const weight = parseFloat(item?.gram);
      if (target.classList.contains("active")) {
        if (modifier) {
          this.modifPriceTotal -= price;
          this.dataModifiers.add = this.dataModifiers.add.filter(elem => elem != item.id);
        }
        this.modifKcal -= kcal;
        this.modifProtein -= proteint;
        this.modifFats -= fats;
        this.modifCarbohydrates -= carbohydrates;
        this.modifWeight -= weight;
        this.dataModifiers.remove.push(item.id);
      } else {
        this.dataModifiers.remove = this.dataModifiers.remove.filter(elem => elem != item.id);
        this.modifKcal += kcal;
        this.modifProtein += proteint;
        this.modifFats += fats;
        this.modifCarbohydrates += carbohydrates;
        this.modifWeight += weight;
        if (modifier) {
          this.modifPriceTotal += price;
          this.dataModifiers.add.push(item.id);
        }
        this.modifPriceTotal = this.modifPriceTotal < 0 ? 0 : this.modifPriceTotal;
      }
      target.classList.toggle("active");
    },
    randomDish(array) {
      let rand = Math.floor(Math.random() * array.length);
      this.randoDishItem = array[rand];
    },
    recommendedOpen() {
      this.$emit("recommendedOpen", this.randoDishItem);
    },
    switchVariant(index) {
      this.activeIndexKKal = index;
    },
    activeDayBtn(day) {
      return Object.keys(day?.items).length > 0;
    },
    moveSlide(e, b) {
      this.activeIndexKKal = 0;
      if (b == "max") {
        if (e == 0) {
          this.activeDishIndex = this.dishesList.length - 1;
        } else if (e == this.dishesList.length + 1) {
          this.activeDishIndex = 0;
        } else {
          this.activeDishIndex = e - 1;
        }
      } else {
        if (e == 1) {
          this.activeDishIndex = this.dishesList.length - 1;
        } else if (e == this.dishesList.length + 2) {
          this.activeDishIndex = 0;
        } else {
          this.activeDishIndex = e - 2;
        }
      }
      const ingredients = document.querySelectorAll(".modal-container [data-attr='ingredient']");
      const modifiers = document.querySelectorAll(".modal-container [data-attr='modifier']");
      ingredients.forEach(item => {
        item.classList.add("active");
      });
      modifiers.forEach(item => {
        item.classList.remove("active");
      });
      this.randomDish(this.recommendedDishes);
      this.modifPriceTotal = 0;
      this.modifKcal = 0;
      this.modifProtein = 0;
      this.modifFats = 0;
      this.modifCarbohydrates = 0;
      this.modifWeight = 0;
      this.dataModifiers.add = [];
      this.dataModifiers.remove = [];
      const ingredientBtns = document.querySelectorAll("[data-attr='ingredient']");
      if (ingredientBtns) {
        ingredientBtns.forEach(btn => {
          btn.classList.add("active");
        });
      }

      const modifBtns = document.querySelectorAll("[data-attr='modifier']");
      if (modifBtns) {
        modifBtns.forEach(btn => {
          btn.classList.remove("active");
        });
      }
    },
    async addDishToDay(day, dish, index, dataModifiers) {
      let variant_id = this.activeDish.info[this.activeIndexKKal].id;
      this.$emit("addDishToDay", { day, dish, index, variant_id, dataModifiers });
    },
    paginationGroup() {
      const dataId = this.dishesList[this.activeDishIndex].category.slug;
      const bullets = document.querySelectorAll(".swiper-pagination-bullet");
      bullets.forEach(item => {
        if (item.getAttribute("data-id") != dataId) {
          item.style.display = "none";
        } else {
          item.removeAttribute("style");
        }
      });
    }
  },
  mounted() {
    document.querySelector("body").classList.add("overflowed");
    this.randomDish(this.recommendedDishes);
    if (this.dishesList.length > 1) {
      this.swiper = new Swiper(".swiper-container", this.options.option);
    } else {
      this.swiper = new Swiper(".swiper-container", this.options.option2);
    }
    this.paginationGroup();
    this.swiper.on("slideChange", item => {
      this.moveSlide(item.activeIndex, item.currentBreakpoint);
      this.paginationGroup();
    });
  },
  unmounted() {
    document.querySelector("body").classList.remove("overflowed");
  }
};
</script>

<style lang="scss" scoped>
.modifier-wrapper {
  float: left;
}
.modifier {
  padding: 0 8px;
  font-size: 14px;
  line-height: 24px;
  color: var(--dark-100);
  background: var(--bg-main);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  text-transform: lowercase;
  &.active {
    color: var(--bg-white);
    background: var(--blue-main);
  }
}
.slider-icon-box {
  margin-left: 23px;
  display: flex;
  align-self: center;
  position: relative;
  img {
    margin-right: 8px;
    height: 24px;
    width: 16px;
    &.red {
      position: relative;
      bottom: 2px;
    }
  }
  span.green.red {
    color: var(--error);
  }
  .info-meassage {
    text-transform: none;
  }
  &:hover .info-meassage {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear 0s, opacity 300ms;
  }
}

.modal-container {
  z-index: 50;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: var(--white-100);
}
.scroll {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  align-items: start;
  &::-webkit-scrollbar {
    width: 0;
  }
}
.header {
  z-index: 20;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 12px;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
}
.logo {
  margin-top: -2px;
}
.header-user {
  margin-left: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 767px) {
    margin: 0 auto;
    padding-left: 19px;
  }
}
.user-name {
  text-transform: uppercase;
  transform: translate(-1px, 1.5px);
}
.user-limit {
  transform: translate(-1px, -2px);
  color: var(--blue-main);
}
.close-btn {
  margin-left: 76px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  background: var(--white-100);
  border-radius: 10px;
  &:hover {
    background: var(--blue-main);
    svg {
      color: var(--white-100);
    }
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
}
.main-content {
  min-height: 100vh;
  display: flex;
  width: 100%;
}
.left-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 532px;
  min-width: 532px;
  height: 100%;
  padding: 180px 20px 20px;
  @media (max-width: 1024px) {
    padding-bottom: 136px;
  }
}
.category-dishes {
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  svg {
    margin-left: 24px;
    margin-right: 8px;
    margin-top: -3px;
  }
  .green {
    color: var(--success);
  }
}
.dish-description {
  margin-top: 8px;
  margin-bottom: 30px;
  color: var(--dark-40);
  p {
    display: flow-root;
    line-height: 30px;
  }
}
.total-price {
  display: flex;
  flex-direction: column;
  line-height: 48px;

  @media (max-width: 1024px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .blue {
    font-family: "Aeonik Pro", sans-serif;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
  }
}
.checkbox-label input[type="checkbox"] {
  display: none;
}
.days-box {
  display: flex;
  width: 100%;
}
.checkbox-label {
  position: relative;
  width: 100%;
  margin: 0 2px;
}
.day-card-info {
  position: relative;
  z-index: 2;
  transition: 0.2s ease-in;
  position: absolute;
  bottom: calc(100% + 10px);
  visibility: hidden;
  opacity: 0;
  .dish-day {
    box-shadow: var(--box-shadow-second);
  }
  .arrow-info {
    transform: rotate(-90deg);
    top: calc(100% - 10px);
    left: 26px;
    position: absolute;
    border-color: transparent var(--white-100);
    border-style: solid;
    border-width: 13px 13px 13px 0px;
    height: 0px;
    width: 0px;
  }
}
.checkbox-label:hover .day-card-info {
  visibility: visible;
  opacity: 1;
}
.day {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f8fa;
  border-radius: 8px;
  height: 100%;
  min-height: 48px;
  width: 100%;
  cursor: pointer;
  svg {
    margin-top: -2px;
    margin-right: 6px;
    display: none;
  }
  svg.plus {
    margin-top: -5px;
  }
  &:hover {
    background: var(--blue-main);
    color: var(--white-100);
    svg.plus {
      display: flex;
    }
  }
}
.day.active {
  background: var(--blue-main);
  color: white;
  svg {
    display: flex;
  }
  svg.plus {
    display: none;
  }
  &:hover {
    svg {
      display: none;
    }
    svg.plus {
      display: flex;
    }
  }
}
.right-container {
  width: calc(100% - 532px);
  height: 100%;
  background: var(--bg-main);
}
.kkal-box {
  display: flex;
  min-height: 24px;
  align-items: center;
  margin-bottom: 16px;
  margin-top: 16px;
  span.part {
    margin-right: 8px;
    font-family: "Aeonik Pro", sans-serif;
  }
}
.kkal-cell {
  cursor: pointer;
  width: 48px;
  height: 24px;
  background: var(--bg-main);
  border-radius: 8px;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-100);
  margin-right: 4px;
  &.active {
    color: var(--white-100);
    background: var(--blue-main);
  }
}
.kkal-details-box {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.part-box {
  display: flex;
  align-items: center;
}
.kkal-details {
  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    &:last-child {
      margin-left: 4px;
      color: var(--dark-40);
    }
  }
  &:not(:last-child) {
    margin-right: 16px;
  }
}
.part {
  text-transform: uppercase;
}
@media (max-width: 1024px) {
  .main-content {
    min-height: 900px;
  }
  .scroll {
    overflow-x: hidden;
    max-width: 100vw;
  }
  .main-content {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
  .left-container {
    max-width: 100%;
    padding-top: 20px;
  }
  .right-container {
    width: 100%;
  }
  .checkbox-label:hover .day-card-info {
    visibility: hidden;
    opacity: 0;
  }
}
@media (max-width: 764px) {
  .left-container {
    min-width: auto;
  }
  .right-container {
    min-height: 420px;
  }
  .kkal-box {
    flex-direction: column;
    align-items: flex-start;
  }
  .kkal-details-box {
    margin-left: 0;
    margin-top: 8px;
  }
}
@media (max-width: 375px) {
  .close-btn {
    margin-left: 0px;
  }
  .header {
    display: flex;
    justify-content: space-between;
  }
}
.blue {
  color: var(--blue-main);
}
.recommended-dish {
  margin: auto 0 30px;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--bg-main);
  border-radius: 8px;

  @media (max-width: 1024px) {
    margin: 0;
    flex-direction: column;
    align-items: flex-start;
  }

  img {
    margin-right: 20px;
    width: 52px;
    height: 52px;
    filter: drop-shadow(-3px 8px 10px rgba(0, 0, 0, 0.04));
  }
  &-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & > div {
      display: flex;
      flex-direction: column;
    }
  }
  &-text {
    font-size: 12px;
    text-transform: uppercase;
    line-height: 24px;

    span {
      white-space: nowrap;
    }
  }
  &-open {
    margin-left: 10px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 22px;
    color: rgba(0, 14, 41, 0.4);
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    background: #fff;
    border-radius: 8px;
    cursor: pointer;

    @media (max-width: 1024px) {
      margin-top: 16px;
      margin-left: 0;
      width: 100%;
    }
  }
}
.dish-slider-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1024px) {
    flex-direction: column;
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 20px;
    margin-bottom: 0;
    background: #fff;
    z-index: 3;
    box-sizing: border-box;
  }

  .days-box {
    max-width: 316px;
    height: 100%;

    @media (max-width: 1024px) {
      max-width: none;
    }
  }
}
.dish-slider-bottom-wrapper {
  margin-top: auto;
  @media (max-width: 1024px) {
    display: flex;
    flex-direction: column-reverse;
    .dish-slider-bottom {
      display: none;
    }
  }
}
.dish-slider-bottom.fixed {
  z-index: 51;
  @media (min-width: 1025px) {
    display: none;
  }
}
</style>
