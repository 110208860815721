<template>
  <div class="dishes-page">
    <DarkBg class="dark-bg-component"></DarkBg>
    <DishSlider class="dish-slider" :options="sliderOptions" @recommendedOpen="recommendedOpen" @addDishToDay="addDishToDay" @removeDishFromDay="removeDishFromDay" :recommendedDishes="RECOMMENDED_DISHES" :currentUser="SELECT_USER ? SELECT_USER : USER" :dishesList="dishSliderData" :activeIndex="activeSlideDish" :dishes="dishesDayArr" v-if="dishSlider" v-model:show="dishSlider" :key="sliderKey"></DishSlider>
    <Dialog class="cards-modal" v-if="dialogOverLimit.show" v-model:show="dialogOverLimit.show" @cancelDialog="cancelLimitDialog" @submitDialog="submitLimitDialog" @submitDialog_2="submitLimitDialog_2" :firstBtnText="`${DISHES_TRANSLATE?.modals?.orders?.exceed?.btn?.pay} ${dialogOverLimit.surcharge_price} ₴`" :secondBtnText="DISHES_TRANSLATE?.modals?.orders?.exceed?.btn?.cancel">
      <template v-slot:title>
        {{ DISHES_TRANSLATE?.modals?.orders?.exceed?.title_1 }} <span class="grey-text">{{ dialogOverLimit.day }}</span> {{ DISHES_TRANSLATE?.modals?.orders?.exceed?.title_2 }} <span class="blue">{{ dialogOverLimit.surcharge_price }} ₴ </span>
      </template>
      <div class="grey-text m-text-des-n surcharge-text">{{ DISHES_TRANSLATE?.modals?.orders?.exceed?.description }}</div>
      <!-- <template v-slot:select>
        <div v-if="GET_CARDS?.length > 0" class="cards-select-wrapper">
          <InputSelect :options="GET_CARDS" @selectPressed="selectCard" :label="DISHES_TRANSLATE?.modals?.orders?.exceed?.card" :cardEmptyText="DISHES_TRANSLATE?.modals?.orders?.exceed?.card_empty" :cardEmpty="true"></InputSelect>
        </div>
      </template> -->
    </Dialog>
    <Dialog class="cards-modal" v-if="dialogMinimumOrderAmount.show && !dialogMinimumOrderAmount.dont_show_days" v-model:show="dialogMinimumOrderAmount.show" @cancelDialog="cancelMinimumOrderAmountDialog" @submitDialog="applyMinimumOrderAmountDialog" @submitDialog_2="cancelMinimumOrderAmountDialog" :firstBtnText="`${DISHES_TRANSLATE?.modals?.orders?.min_amount?.btn?.ok}`" :secondBtnText="DISHES_TRANSLATE?.modals?.orders?.min_amount?.btn?.cancel">
      <template v-slot:title>
        {{ DISHES_TRANSLATE?.modals?.orders?.min_amount?.title_1 }} <span class="grey-text">{{ dialogMinimumOrderAmount.day }}</span> {{ DISHES_TRANSLATE?.modals?.orders?.min_amount?.title_2 }} <span class="blue">{{ dialogMinimumOrderAmount.amount }} ₴ </span>
      </template>

      <div class="grey-text m-text-des-n surcharge-text">{{ DISHES_TRANSLATE?.modals?.orders?.min_amount?.description }}</div>
      <div class="confirm-box" @click.prevent="dialogMinimumOrderAmount.dont_show = !dialogMinimumOrderAmount.dont_show">
        <label class="check-confirm">
          <input type="checkbox" v-model="dialogMinimumOrderAmount.dont_show" />
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.0643 18L20.4016 6M8.97718 17.9996L3.60156 12.2635" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </label>
        <span class="grey-text s-text-alert">
          {{ DISHES_TRANSLATE?.modals?.orders?.min_amount?.checkbox.dont_show }}
        </span>
      </div>
    </Dialog>
    <Dialog v-if="dialogPaymentShow" v-model:show="dialogPaymentShow" showBtns="false">
      <template v-slot:title>{{ DISHES_TRANSLATE?.modals?.orders?.exceed?.payment_iframe }}</template>
      <iframe id="paymentFrame" :src="paymentLink" allowpaymentrequest="true" allow="payment"></iframe>
    </Dialog>
    <Dialog class="dialog-preference" v-if="dialogPreferences" v-model:show="dialogPreferences" @cancelDialog="cancelDialogPreferences" @submitDialog="submitDialogPreferences" :firstBtnText="DISHES_TRANSLATE?.modals?.preferences?.btn?.save">
      <template v-slot:title>
        <span v-if="SELECT_USER" class="r-title-2">
          {{ DISHES_TRANSLATE?.modals?.preferences?.employee?.title }} <span class="blue font-regular">{{ SELECT_USER?.name }} {{ SELECT_USER?.last_name }} </span>
        </span>
        <span v-else class="r-title-2">{{ DISHES_TRANSLATE?.modals?.preferences?.my?.title }}</span>
      </template>
      <div class="grey-text m-text-des-n preference-text" v-if="SELECT_USER">{{ DISHES_TRANSLATE?.modals?.preferences?.employee?.description }}</div>
      <div class="grey-text m-text-des-n preference-text" v-else>{{ DISHES_TRANSLATE?.modals?.preferences?.my?.description }}</div>
      <div class="products-alergie-list">
        <div class="checkbox-box">
          <div v-for="categoryAllergen in PREFERENCES_ALL" :key="categoryAllergen.name">
            <div v-if="categoryAllergen?.items?.length > 0" class="card-header">
              <span class="products-title s-text-caption">{{ categoryAllergen.name }}</span>
            </div>
            <div v-if="categoryAllergen?.items?.length > 0" class="card-content review-btn-fix">
              <div class="checkbox-box">
                <div @click="switchAllergen(allergen)" class="pref-checkbox s-text-caption" :class="{ active: checkForAllergen(allergen) }" v-for="allergen in categoryAllergen?.items" :key="allergen.id">
                  {{ allergen.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
    <DialogChooseEmployee v-model:show="CHOOSE_EMPLOYEE"></DialogChooseEmployee>
    <div class="main" :class="{ 'micro-mode': dishesModeMicro }">
      <div class="left-box" :class="{ sticky: stickyFlag && infoStep == 0 }">
        <h2 class="r-title-1">{{ DISHES_TRANSLATE?.dishes?.title }}</h2>
        <div id="dish-menu-anchor"></div>
        <div class="dish-menu-nav" :class="{ sticky: stickyFlag && infoStep == 0 }">
          <div v-if="DISHES_CATEGORIES" class="dish-category-list">
            <a v-if="preferredDishes && !search" class="s-text-caption" :class="{ active: activeCategoryLink == preferredDishes?.name }" v-smooth-scroll="{ duration: 700, updateHistory: false }" href="#prefferedDishes">{{ preferredDishes?.name }}</a>
            <template v-for="category in DISHES_CATEGORIES" :key="category?.id">
              <a v-if="navCategory(category)" class="s-text-caption" :class="{ active: activeCategoryLink == category?.name }" v-smooth-scroll="{ duration: 700, updateHistory: false }" @click="selectCategory(category?.name)" :href="'#' + category?.id">{{ category?.name }}</a>
            </template>
          </div>
          <div v-if="!stickyFlag" class="dish-menu-nav-btns" :class="{ overflowed: infoStep == 0 }">
            <InfoBtn @click="stepsReinit">
              <div class="guest-info">
                <div class="guest-info-inner">
                  {{ DISHES_TRANSLATE?.tooltips?.dishes }}
                </div>
              </div>
            </InfoBtn>
            <SearchDish :class="{ user: userRole == 1 }" :placeholder="DISHES_TRANSLATE?.dishes?.search" v-model:value.trim="search"></SearchDish>
            <div class="preference-info-btn">
              <ButtonPreferences @click="selectPreference">
                <span>{{ DISHES_TRANSLATE?.dishes?.preferences }}</span>
              </ButtonPreferences>
              <InfoMessage v-if="userRole == 2" right="-75px" top="135%" arrowDir="tc" :class="{ show: infoStep == 2 }">
                <div class="steps-box">
                  <span class="step-text m-text-steps-title">{{ DISHES_TRANSLATE?.tips?.second }}</span>
                  <div class="step-interactive-box">
                    <span></span>
                    <span class="active"></span>
                    <span class="only-mob"></span>
                    <span></span>
                    <a v-if="screenWidth > 764" href="#step-4" v-smooth-scroll="{ duration: 700, offset: -600, updateHistory: false }" @click="nextInfoStep" class="step-btn s-text-alert">
                      {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                    </a>
                    <a v-else @click="nextInfoStep" class="step-btn s-text-alert">
                      {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                    </a>
                  </div>
                </div>
              </InfoMessage>
              <InfoMessage v-else right="-75px" top="135%" arrowDir="tc" :class="{ show: infoStep == 1 }">
                <div class="steps-box">
                  <span class="step-text m-text-steps-title">{{ DISHES_TRANSLATE?.tips?.second }}</span>
                  <div class="step-interactive-box">
                    <span class="active"></span>
                    <span class="only-mob"></span>
                    <span></span>
                    <a v-if="screenWidth > 764" href="#step-4" v-smooth-scroll="{ duration: 700, offset: -600, updateHistory: false }" @click="nextInfoStep" class="step-btn s-text-alert">
                      {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                    </a>
                    <a v-else @click="nextInfoStep" class="step-btn s-text-alert">
                      {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                    </a>
                  </div>
                </div>
              </InfoMessage>
            </div>
            <div class="swich-mode-dish">
              <div class="swich-mode-dish-btn" @click="dishesModeMicro = !dishesModeMicro">
                <svg v-if="dishesModeMicro" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="path-1-inside-1" fill="white">
                    <rect width="7" height="7" rx="1" />
                  </mask>
                  <rect width="7" height="7" rx="1" stroke="#3CAFE9" stroke-width="2.8" mask="url(#path-1-inside-1)" />
                  <mask id="path-2-inside-2" fill="white">
                    <rect y="9" width="7" height="7" rx="1" />
                  </mask>
                  <rect y="9" width="7" height="7" rx="1" stroke="#3CAFE9" stroke-width="2.8" mask="url(#path-2-inside-2)" />
                  <mask id="path-3-inside-3" fill="white">
                    <rect x="9" width="7" height="7" rx="1" />
                  </mask>
                  <rect x="9" width="7" height="7" rx="1" stroke="#3CAFE9" stroke-width="2.8" mask="url(#path-3-inside-3)" />
                  <mask id="path-4-inside-4" fill="white">
                    <rect x="9" y="9" width="7" height="7" rx="1" />
                  </mask>
                  <rect x="9" y="9" width="7" height="7" rx="1" stroke="#3CAFE9" stroke-width="2.8" mask="url(#path-4-inside-4)" />
                </svg>
                <svg v-if="!dishesModeMicro" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <mask id="path-1-inside-1" fill="white">
                    <rect width="16" height="7" rx="1" />
                  </mask>
                  <rect width="16" height="7" rx="1" stroke="#3CAFE9" stroke-width="2.8" mask="url(#path-1-inside-1)" />
                  <mask id="path-2-inside-2" fill="white">
                    <rect y="9" width="16" height="7" rx="1" />
                  </mask>
                  <rect y="9" width="16" height="7" rx="1" stroke="#3CAFE9" stroke-width="2.8" mask="url(#path-2-inside-2)" />
                </svg>
              </div>
              <InfoMessage v-if="userRole == 2" left="0px" top="63px" arrowDir="tl" :class="{ show: infoStep == 3 }">
                <div class="steps-box">
                  <span class="step-text m-text-steps-title">{{ DISHES_TRANSLATE?.tips?.mobile }}</span>
                  <div class="step-interactive-box">
                    <span></span>
                    <span class="only-mob"></span>
                    <span class="active"></span>
                    <span></span>
                    <a href="#step-4" v-smooth-scroll="{ duration: 700, offset: -180, updateHistory: false }" @click="nextInfoStep" class="step-btn s-text-alert">
                      {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                    </a>
                  </div>
                </div>
              </InfoMessage>
              <InfoMessage v-else left="0px" top="63px" arrowDir="tl" :class="{ show: infoStep == 2 }">
                <div class="steps-box">
                  <span class="step-text m-text-steps-title">{{ DISHES_TRANSLATE?.tips?.mobile }}</span>
                  <div class="step-interactive-box">
                    <span></span>
                    <span class="active"></span>
                    <span></span>
                    <a href="#step-4" v-smooth-scroll="{ duration: 700, offset: -180, updateHistory: false }" @click="nextInfoStep" class="step-btn s-text-alert">
                      {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                    </a>
                  </div>
                </div>
              </InfoMessage>
            </div>
          </div>
        </div>
        <div class="global-dishes-container" v-if="DISHES && DISHES_CATEGORIES">
          <transition-group name="list">
            <div key="prefferedDishes" id="prefferedDishes" class="dishes-category-sort-box">
              <div class="dishes-list-box" v-if="preferredDishes && !search">
                <DishCard @submitSlider="sliderOpen(dish)" @clickDay="addDishToDay" v-for="(dish, index) in preferredDishes.dishes" :dish="dish" :key="dish.id">
                  <template v-slot:introduction v-if="index == 0">
                    <InfoMessage v-if="userRole == 2 && preferredDishes.dishes" id="step-4" class="step-in-card" left="26px" bottom="-148px" arrowDir="tc" :class="{ show: infoStep == 4 }">
                      <div class="steps-box">
                        <span class="step-text m-text-steps-title">{{ DISHES_TRANSLATE?.tips?.third }} </span>
                        <div class="step-interactive-box">
                          <span></span>
                          <span class="only-mob"></span>
                          <span></span>
                          <span class="active"></span>
                          <div @click="nextInfoStep" class="step-btn s-text-alert">
                            {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                          </div>
                        </div>
                      </div>
                    </InfoMessage>
                    <InfoMessage v-else id="step-4" class="step-in-card" left="26px" bottom="-148px" arrowDir="tc" :class="{ show: infoStep == 4 }">
                      <div class="steps-box">
                        <span class="step-text m-text-steps-title">{{ DISHES_TRANSLATE?.tips?.third }} </span>
                        <div class="step-interactive-box">
                          <span class="only-mob"></span>
                          <span></span>
                          <span class="active"></span>
                          <div @click="nextInfoStep" class="step-btn s-text-alert">
                            {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                          </div>
                        </div>
                      </div>
                    </InfoMessage>
                  </template>
                </DishCard>
              </div>
            </div>
            <div class="dishes-category-sort-box" :id="category.id" v-for="(category, index) in searchDishResult" :key="index">
              <h2 v-if="availabilityOfSearchResults && category.dishes" class="r-title-1">{{ category.category_name }}</h2>
              <div v-if="(!availabilityOfSearchResults && search ? false : true) && category.dishes" class="dishes-list-box">
                <DishCard @submitSlider="sliderOpen(dish)" @clickDay="addDishToDay" v-for="(dish, index2) in category.dishes" :dish="dish" :key="index2">
                  <template v-slot:introduction v-if="index == 0 && index2 == 0">
                    <InfoMessage v-if="userRole == 2 && !preferredDishes.dishes" id="step-4" class="step-in-card" left="0" bottom="-164px" arrowDir="tc" :class="{ show: infoStep == 4 }">
                      <div class="steps-box">
                        <span class="step-text m-text-steps-title">{{ DISHES_TRANSLATE?.tips?.third }} </span>
                        <div class="step-interactive-box">
                          <span></span>
                          <span class="only-mob"></span>
                          <span></span>
                          <span class="active"></span>
                          <div @click="nextInfoStep" class="step-btn s-text-alert">
                            {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                          </div>
                        </div>
                      </div>
                    </InfoMessage>
                    <InfoMessage v-else id="step-4" class="step-in-card" left="26px" bottom="-148px" arrowDir="tc" :class="{ show: infoStep == 4 }">
                      <div class="steps-box">
                        <span class="step-text m-text-steps-title">{{ DISHES_TRANSLATE?.tips?.third }} </span>
                        <div class="step-interactive-box">
                          <span class="only-mob"></span>
                          <span></span>
                          <span class="active"></span>
                          <div @click="nextInfoStep" class="step-btn s-text-alert">
                            {{ DISHES_TRANSLATE?.tips?.btn?.next }}
                          </div>
                        </div>
                      </div>
                    </InfoMessage>
                  </template>
                </DishCard>
              </div>
            </div>
            <div v-if="!availabilityOfSearchResults && search" class="no-search-result m-text-des" key="notFoundKey">
              <p>
                <span>{{ DISHES_TRANSLATE?.search?.not_found?.title }}&nbsp; </span>
                <span>{{ DISHES_TRANSLATE?.search?.not_found?.description }}</span>
              </p>
            </div>
          </transition-group>
        </div>
      </div>
      <div class="modal-right-box" :key="rightBoxKey" :class="{ active: mobDishesPerDay }" v-touch:press="touchHoldHandler">
        <div class="modal-right-box-top"></div>
        <div class="modal-drag-btn" @click="mobDishFuncClose2"></div>
        <div class="right-box fullHeight" v-touch:swipe.bottom="mobDishFuncClose">
          <DishDaysCard :day="item" v-for="(item, index) in ORDERS" :key="index" @clearLink="clearLink" @closeMobile="mobDishFuncClose" @closeMobile2="mobDishFuncClose2" @showPayment="showPayment"></DishDaysCard>
        </div>
      </div>
    </div>
    <a v-if="pageYOffset > topOffset" v-smooth-scroll="{ duration: 700, updateHistory: false }" href="#navDishes" class="btn-to-top">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 10L12 3M19 10L12 3M12 3L12 21" stroke="#000E29" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    </a>
    <Footer></Footer>
    <div class="mob-footer-btn m-text-des" @click="mobDishFuncOpen" :class="{ active: !mobDishesPerDay }">
      {{ DISHES_TRANSLATE?.orders?.btn?.open_list }}
    </div>
  </div>
</template>

<script>
import DarkBg from "@/components/dark-bg.vue";
import DishCard from "@/components/dish-card";
import DishDaysCard from "@/components/dish-days-card";
import ButtonPreferences from "@/components/parts/button-preferences";
import SearchDish from "@/components/parts/search-dish";
import InfoBtn from "@/components/parts/info-btn";
import InfoMessage from "@/components/parts/info-message";
import Footer from "@/components/footer-setting.vue";
import Dialog from "@/components/dialogs/dialog-submit-setting.vue";
import DialogChooseEmployee from "@/components/dialogs/choose-employee.vue";
import DishSlider from "@/components/dish-slider.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DarkBg,
    DialogChooseEmployee,
    DishCard,
    ButtonPreferences,
    SearchDish,
    // InputSelect,
    InfoBtn,
    DishDaysCard,
    DishSlider,
    InfoMessage,
    Dialog,
    Footer
  },
  data() {
    return {
      sliderOptions: null,
      counterFlag: false,
      link: null,
      checker: [],
      rightBoxKey: 1,
      addDishFlag: true,
      scrollWidth: 0,
      sliderKey: 1,
      screenWidth: null,
      dishesDayArr: [],
      categoryLink: null,
      topOffset: 0,
      pageYOffset: 0,
      stickyFlag: false,
      mobDishesPerDay: false,
      activeSlideDish: null,
      dishSliderData: null,
      dishSlider: false,
      cvvShow: false,
      dialogOverLimit: {
        item_id: null,
        surcharge_id: null,
        surcharge_price: null,
        card: null,
        order_id: null,
        dish_price: null,
        dish_qty: null,
        day: null,
        limit: null,
        show: false
      },
      dialogMinimumOrderAmount: {
        day: '',
        amount: null,
        show: false,
        dont_show: false,
        dont_show_days: false,
      },
      dialogDeleteConfirm: false,
      dialogPreferences: false,
      dishesModeMicro: false,
      dialogPaymentShow: false,
      infoStep: 0,
      search: "",
      swiperPos: 0,
      rightBox: 0
    };
  },
  computed: {
    ...mapGetters(["SURCHARGE_CHECKER", "GET_INFO_STEP", "CHOOSE_EMPLOYEE", "DAY_COMPLETE", "GET_TIMER", "GET_CARDS", "GET_PAYMENT_LINK", "RECOMMENDED_DISHES", "DISHES", "PREFERRED_DISHES", "DISHES_CATEGORIES", "DISHES_TRANSLATE", "ORDERS", "PREFERENCES_ALL", "COMPANY", "USER", "SELECT_USER"]),
    activeCategoryLink() {
      return this.categoryLink || this.preferredDishes?.name || this.DISHES_CATEGORIES?.[0]?.name;
    },
    paymentLink() {
      return this.link ? this.link : this.GET_PAYMENT_LINK?.link;
    },
    preferredDishes() {
      if (Object.values(this.PREFERRED_DISHES?.dishes).length > 0) {
        let preferredArray = [];
        Object.values(this.PREFERRED_DISHES?.dishes).forEach((item, index) => {
          preferredArray[index] = Object.values(item)[0];
        });
        return {
          dishes: preferredArray,
          name: this.PREFERRED_DISHES?.name
        };
      }
      return false;
    },
    sortDishCategory() {
      let tempArr = [];
      this.DISHES_CATEGORIES.forEach(e => {
        let x = {
          category_name: this.categoryCheck(e.id),
          id: e.id,
          dishes: this.DISHES[e.id]
        };
        tempArr.push(x);
      });
      return tempArr;
    },
    userRole() {
      return this.USER.role.id;
    },
    cleanDishesArr() {
      let allDishes = [];
      let keysCategory = Object.keys(this.DISHES);
      keysCategory.forEach(key => {
        allDishes = [...allDishes, ...this.DISHES[key]];
      });
      return allDishes;
    },
    searchDishResult() {
      let respArr = [...this.sortDishCategory];
      if (this.search) {
        let resultSearchArr = this.cleanDishesArr.filter(e => e.name.toUpperCase().includes(this.search.toUpperCase()));
        respArr = [
          {
            category_name: "Результат",
            dishes: resultSearchArr,
            id: "qwerty"
          }
        ];
        return respArr;
      } else {
        return respArr;
      }
    },
    availabilityOfSearchResults() {
      return this.searchDishResult[0]?.dishes?.length > 0;
    }
  },
  methods: {
    ...mapActions(["GetCards", "SetPaymentLink", "DeleteOrders", "GetOrders", "GetRecommendedDishes", "GetAllDishes", "UpdateCompany", "EditEmployeeSettings", "SelectUser", "DishesTranslate", "PostOrders", "EmployeesList", "EmployeesTranslate", "GetAllPreferences"]),
    clearLink(day) {
      this.link = null;

      this.checkIsMinOrderAmount(day);
    },
    navCategory(category) {
      let checker = this.searchDishResult.filter(item => {
        return item.id == category.id;
      });
      return checker[0]?.dishes || false;
    },
    selectCategory(categoryName) {
      this.categoryLink = categoryName;
    },
    scrollWidthFunc() {
      const body = document.querySelector("body");
      this.scrollWidth = window.innerWidth - body.clientWidth;
    },
    bodyScrollBlock() {
      document.querySelector("body").style.overflow = "hidden";
      document.querySelector("body").style.paddingRight = `${this.scrollWidth}px`;
    },
    bodyScrollBlockDecline() {
      document.querySelector("body").style.paddingRight = null;
      document.querySelector("body").style.overflow = null;
    },
    checkForAllergen(allergenIn) {
      let x = false;
      let activeUser = this.SELECT_USER || this.USER;
      activeUser?.preferences?.allergen.forEach(e => {
        if (e.id == allergenIn.id) x = true;
      });
      activeUser?.preferences?.other.forEach(e => {
        if (e.id == allergenIn.id) x = true;
      });
      return x;
    },
    async switchAllergen(allergenIn) {
      let preferences = {};
      preferences.allergen = [];
      preferences.other = [];

      if (allergenIn.type == "allergen") {
        preferences.allergen.push(allergenIn.id);
      } else if (allergenIn.type == "other") {
        preferences.other.push(allergenIn.id);
      }

      let temp = { preferences };
      let res = await this.EditEmployeeSettings({
        user: this.SELECT_USER?.id || this.USER.id,
        company: this.COMPANY.id,
        temp
      });
      if (res) await this.GetAllPreferences();
    },
    selectPreference() {
      document.querySelector("body").style.overflow = "hidden";
      this.dialogPreferences = true;
    },
    async submitDialogPreferences() {
      this.dialogPreferences = false;
      document.querySelector("body").style.overflow = null;
      await this.GetAllDishes("?isGroup=1");
    },
    async cancelDialogPreferences() {
      await this.GetAllDishes("?isGroup=1");
    },
    async selectUser() {
      document.querySelector("body").style.overflow = "hidden";
      this.$store.commit("Set_Choose_Employee", true);
      await this.EmployeesList();
    },
    sliderOpen(item) {
      let index = null;
      let tempArr = [];
      if (this.search) {
        tempArr = [...this.searchDishResult[0]?.dishes];
        index = tempArr.findIndex(e => e.id == item.id);
        tempArr = [...tempArr];
      } else {
        index = this.cleanDishesArr.findIndex(e => e.id == item.id);
        tempArr = this.cleanDishesArr;
      }
      this.dishSliderData = tempArr;
      this.activeSlideDish = index;
      this.sliderOptions = {
        option: {
          loop: true,
          slidesPerView: 1,
          initialSlide: this.activeSlideDish,
          centeredSlides: true,
          spaceBetween: 30,
          pagination: {
            el: ".swiper-pagination",
            renderBullet: function(index, className) {
              return `<span class=${className} data-id=${tempArr?.[index]?.category?.slug}></span>`;
            },
            clickable: true
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          breakpoints: {
            766: {
              slidesPerView: 2
            }
          }
        },
        option2: {
          slidesPerView: 1,
          allowTouchMove: false
        }
      };
      this.dishSlider = true;
    },
    recommendedOpen(item) {
      this.search = "";
      let index = null;
      let tempArr = [];
      if (this.search) {
        tempArr = [...this.searchDishResult[0]?.dishes];
        index = tempArr.findIndex(e => e.id == item.id);
        tempArr = [...tempArr];
      } else {
        index = this.cleanDishesArr.findIndex(e => e.id == item.id);
        tempArr = [...this.cleanDishesArr];
        tempArr = [...tempArr];
      }

      this.dishSliderData = tempArr;
      this.activeSlideDish = index;
      this.sliderOptions = {
        option: {
          loop: true,
          slidesPerView: 1,
          initialSlide: this.activeSlideDish,
          centeredSlides: true,
          spaceBetween: 30,
          pagination: {
            el: ".swiper-pagination",
            renderBullet: function(index, className) {
              return `<span class=${className} data-id=${tempArr?.[index]?.category?.slug}></span>`;
            },
            clickable: true
          },
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
          },
          breakpoints: {
            766: {
              slidesPerView: 2
            }
          }
        },
        option2: {
          slidesPerView: 1,
          allowTouchMove: false
        }
      };
      this.sliderKey *= -1;
    },
    categoryCheck(category_id) {
      let name = this.DISHES_CATEGORIES.find(e => e.id == category_id);
      return name.name;
    },
    stepsReinit() {
      this.infoStep = 1;
      this.$store.commit("Set_Info_Step", this.infoStep);
      this.$store.commit("Set_Info_Step", this.infoStep);
      localStorage.setItem("step-dishes", false);
      document.querySelector(".dark-bg-component").style.display = "block";
      document.querySelector(".dish-menu-nav").style.position = "relative";
      document.querySelector(".dish-menu-nav").style.zIndex = "initial";
    },
    nextInfoStep() {
      if (this.userRole == 2) {
        if (this.infoStep <= 4) {
          if (this.infoStep == 2 && this.screenWidth > 764) {
            this.infoStep += 2;
            this.$store.commit("Set_Info_Step", this.infoStep);
          } else {
            this.infoStep++;
            this.$store.commit("Set_Info_Step", this.infoStep);
          }
        }
      } else {
        if (this.infoStep <= 4) {
          if (this.infoStep == 1 && this.screenWidth > 764) {
            this.infoStep += 3;
            this.$store.commit("Set_Info_Step", this.infoStep);
          } else {
            if (this.infoStep == 2) {
              this.infoStep += 2;
              this.$store.commit("Set_Info_Step", this.infoStep);
            } else {
              this.infoStep++;
              this.$store.commit("Set_Info_Step", this.infoStep);
            }
          }
        }
      }

      if (this.infoStep == 5) {
        localStorage.setItem("step-dishes", true);
        document.querySelector(".dark-bg-component").style.display = "none";
        document.querySelector(".dish-menu-nav").style.position = "sticky";
        document.querySelector(".dish-menu-nav").style.zIndex = "2";
        this.infoStep = 0;
        this.$store.commit("Set_Info_Step", this.infoStep);
      }
    },
    async checkIsMinOrderAmount(day) {
      await this.GetOrders();

      const currOrder = this.ORDERS.filter(item => {
        return item.date === day.date;
      });
      
      if (!currOrder) {
        return false;
      }
      
      const userMinOrder = parseInt(this.SELECT_USER?.min_order || this.USER?.min_order || 0);
      console.log(userMinOrder)
      if (!(userMinOrder > 0 && (parseFloat(currOrder[0].price) < parseInt(userMinOrder)))) {
        return false;
      }

      this.dialogMinimumOrderAmount.day = day.name;
      this.dialogMinimumOrderAmount.amount = parseInt(userMinOrder - parseFloat(currOrder[0].price), 0);
      this.dialogMinimumOrderAmount.show = true;
    },
    async addDishToDay({ day, dish, variant_id, dataModifiers }) {
      if (this.addDishFlag) {
        this.addDishFlag = false;
        if (dataModifiers.add.length || dataModifiers.remove.length) {
          await this.PostOrders({
            item_id: dish.id,
            date: day.date,
            variant_id: variant_id,
            modifiers: dataModifiers,
            qty: 1
          });
        } else {
          await this.PostOrders({
            item_id: dish.id,
            date: day.date,
            variant_id: variant_id,
            qty: 1
          });
        }

        let orderDay = this.ORDERS.filter(item => {
          return item.date == day.date;
        });

        let checker = Object.values(orderDay[0].items).filter(item => {
          return item.product.variant_id == variant_id;
        });

        let orderIndex;

        for (let i = 0; i < this.ORDERS.length; i++) {
          if (this.ORDERS[i].date == orderDay[0].date) orderIndex = i;
        }

        checker.length > 0 ? document.querySelector(`.dish-day:nth-child(${orderIndex + 1})`).classList.add("animated") : document.querySelector(`.dish-day:nth-child(${orderIndex + 1})`).classList.remove("animated");

        await this.GetOrders();
        this.addDishFlag = true;

        let currOrder = this.ORDERS.filter(item => {
          return item.date == day.date;
        });

        let is_timer = Object.values(currOrder[0].items).filter(item => {
          return item.timer;
        });

        if (currOrder[0].over_limit && is_timer.length == 0) {
          document.querySelector("body").style.overflow = "hidden";

          let currItem = Object.values(currOrder[0].items).filter(item => {
            return item.product.variant_id == variant_id;
          });

          this.dialogOverLimit.item_id = currItem[0].id;
          this.dialogOverLimit.surcharge_id = currItem[0].surcharge_id;
          this.dialogOverLimit.surcharge_price = currItem[0].surcharge_price;
          this.dialogOverLimit.order_id = currOrder[0].id;
          this.dialogOverLimit.dish_price = currItem[0].product.price;
          this.dialogOverLimit.dish_qty = currItem[0].qty;

          this.dialogOverLimit.day = day.name;
          this.dialogOverLimit.limit = currItem[0].surcharge;
          this.dialogOverLimit.show = true;
        }

        await this.checkIsMinOrderAmount(day);
      }
    },
    async submitLimitDialog() {
      this.dialogOverLimit.show = false;
      if (this.dialogOverLimit.card) {
        await this.SetPaymentLink({
          data: {
            item_id: this.dialogOverLimit.surcharge_id,
            card: this.dialogOverLimit.card
          }
        });
        document.querySelector("body").style.overflow = null;
      } else {
        await this.SetPaymentLink({
          data: {
            item_id: this.dialogOverLimit.surcharge_id
          }
        });
      }
      await this.GetOrders();
      this.rightBoxKey *= -1;
      if (this.GET_PAYMENT_LINK.is_error) this.cancelLimitDialog();
      this.dialogOverLimit.card = null;
      document.location.replace(this.paymentLink);
    },
    async submitLimitDialog_2() {
      this.dialogOverLimit.show = false;
      if (this.dialogOverLimit.card) {
        await this.SetPaymentLink({
          data: {
            item_id: this.dialogOverLimit.surcharge_id,
            card: this.dialogOverLimit.card
          }
        });
      } else {
        await this.SetPaymentLink({
          data: {
            item_id: this.dialogOverLimit.surcharge_id
          }
        });
        localStorage.setItem("isPayment", true);
        this.$router.push({ name: "Payment" });
      }
      if (this.GET_PAYMENT_LINK.is_error) this.cancelLimitDialog();
      document.querySelector("body").style.overflow = null;
      this.dialogOverLimit.card = null;
    },
    async showPayment(item) {
      document.querySelector("body").style.overflow = "hidden";
      this.link = item.link;
      document.location.replace(this.link);
    },
    selectCard(item) {
      this.dialogOverLimit.card = item.id;
    },
    async cancelLimitDialog() {
      await this.DeleteOrders({
        data: {
          item_id: this.dialogOverLimit.surcharge_id ? this.dialogOverLimit.surcharge_id : this.dialogOverLimit.item_id,
          order_id: this.dialogOverLimit.order_id
        },
        dish_price: this.dialogOverLimit.dish_price,
        dish_qty: this.dialogOverLimit.dish_qty
      });
      document.querySelector("body").style.overflow = null;
      this.dialogOverLimit.show = false;
      this.dialogOverLimit.card = null;
    },
    applyMinimumOrderAmountDialog() {
      document.querySelector("body").style.overflow = null;

      if (this.dialogMinimumOrderAmount.dont_show && !this.dialogMinimumOrderAmount.dont_show_days) {
        this.dialogMinimumOrderAmount.dont_show_days = true;
      }

      this.dialogMinimumOrderAmount.show = false;
      this.dialogMinimumOrderAmount.dont_show = false;
    },
    cancelMinimumOrderAmountDialog() {
      document.querySelector("body").style.overflow = null;

      this.dialogMinimumOrderAmount.show = false;
    },
    removeDishFromDay(index) {
      this.link = null;
      this.dishesDayArr.splice(index, 1);
    },
    updateWidth() {
      this.screenWidth = document.documentElement.clientWidth;
    },
    stickyMenu() {
      if (document.querySelector("#dish-menu-anchor")) {
        let offsetTop = document.querySelector("#dish-menu-anchor").getBoundingClientRect().top;
        if (offsetTop < 1) {
          this.stickyFlag = true;
        } else {
          this.stickyFlag = false;
        }
      }
    },
    offsetTop() {
      if (document.querySelector(".global-dishes-container")) {
        this.topOffset = document.querySelector(".global-dishes-container").offsetTop;
      }
    },
    scrollBtnShow() {
      this.pageYOffset = pageYOffset;
    },
    dishMenuWidth() {
      if (document.querySelector(".dishes-list-box") && document.documentElement.clientWidth > 1440) {
        let count = Math.floor((document.querySelector(".dishes-list-box").offsetWidth - 20) / 355);
        let width = count * 335 + 20 * count + 20;
        let padRight = document.querySelector(".left-box").offsetWidth - width + 20;
        if (document.querySelector(".dish-menu-nav")) document.querySelector(".dish-menu-nav").style.paddingRight = `${padRight}px`;
      } else {
        if (document.querySelector(".dish-menu-nav")) document.querySelector(".dish-menu-nav").style.paddingRight = "20px";
      }
      if (document.querySelector(".dish-menu-nav")) document.querySelector(".dish-menu-nav").style.opacity = "1";
    },
    checkerFunc() {
      const arr = document.querySelectorAll(".dishes-list-box");
      Array.from(arr).forEach((item, index) => {
        this.checker[index] = {
          top: window.pageYOffset + item.getBoundingClientRect().top - 600,
          bottom: window.pageYOffset + item.getBoundingClientRect().bottom
        };
      });
    },
    menuObserver() {
      const elem = document.querySelector(".dish-menu-nav");
      let pos = elem.getBoundingClientRect().top + pageYOffset;
      this.checker.forEach((item, index) => {
        if (pos >= item.top && pos <= item.bottom) {
          document.querySelector(".dish-category-list .active").classList.remove("active");
          elem.querySelector(`.dish-category-list > a:nth-child(${index + 1})`).classList.add("active");
        }
      });
    },
    mobDishFuncOpen() {
      this.mobDishesPerDay = true;
      document.querySelector("body").classList.add("dishOverflow");
      document.querySelector("body").style.overflow = "hidden";
    },
    mobDishFuncClose() {
      if (this.swiperPos >= 130 && this.screenWidth <= 764) {
        this.mobDishesPerDay = false;
        document.querySelector("body").classList.remove("dishOverflow");
        document.querySelector("body").style.overflow = null;
      }
    },
    mobDishFuncClose2() {
      this.mobDishesPerDay = false;
      document.querySelector("body").classList.remove("dishOverflow");
      document.querySelector("body").style.overflow = null;
    },
    touchHoldHandler() {
      if (this.screenWidth <= 764) {
        let pos = document.querySelector(".right-box .dish-day").getBoundingClientRect().top;
        this.swiperPos = pos;
      }
    },
    rightBoxPos() {
      let pos = document.querySelector(".right-box").getBoundingClientRect().top;
      this.rightBox = pos;
    }
  },
  async mounted() {
    if (!this.PREFERENCES_ALL) {
      this.GetAllPreferences();
    }
    if (!this.COMPANY) {
      this.UpdateCompany();
    }
    if (!this.DISHES) {
      await this.GetAllDishes("?isGroup=1");
    }
    if (!this.RECOMMENDED_DISHES) {
      this.GetRecommendedDishes();
    }
    if (!this.DISHES_TRANSLATE) {
      this.DishesTranslate();
    }
    if (!this.GET_CARDS) {
      this.GetCards();
    }
    if (!this.ORDERS) {
      await this.GetOrders();
    }
    await this.scrollWidthFunc();
    if (!localStorage.getItem("step-dishes")) {
      localStorage.setItem("step-dishes", true);
      this.infoStep++;
      this.$store.commit("Set_Info_Step", this.infoStep);
      if (document.querySelector(".dark-bg-component")) document.querySelector(".dark-bg-component").style.display = "block";
    }
    if (navigator.userAgent.indexOf("iPhone") > 0 || navigator.userAgent.indexOf("iPad") > 0) document.querySelector("body").classList.add("ios");
    this.updateWidth();
    window.addEventListener("resize", this.updateWidth);
    this.stickyMenu();
    this.dishMenuWidth();
    await this.checkerFunc();
    this.menuObserver();
    this.rightBoxPos();
    this.offsetTop();
    this.scrollBtnShow();
    window.addEventListener("scroll", this.stickyMenu);
    window.addEventListener("resize", this.offsetTop);
    window.addEventListener("scroll", this.scrollBtnShow);
    window.addEventListener("scroll", this.menuObserver);
    window.addEventListener("scroll", this.rightBoxPos);
    window.addEventListener("resize", this.dishMenuWidth);
    window.addEventListener("resize", this.checkerFunc);
    window.addEventListener("resize", this.scrollWidthFunc);

    if (this.ORDERS) {
      let day, surcharge;
      this.ORDERS.filter(item => {
        Object.values(item.items).filter(subitem => {
          if (subitem.surcharge) {
            day = item;
            surcharge = subitem;
          }
        });
      });

      if (surcharge && surcharge?.surcharge_id) {
        this.dialogOverLimit.item_id = surcharge?.id;
        this.dialogOverLimit.surcharge_id = surcharge?.surcharge_id;
        this.dialogOverLimit.surcharge_price = surcharge?.surcharge_price;
        this.dialogOverLimit.order_id = day?.id;
        this.dialogOverLimit.dish_price = surcharge?.product?.price;
        this.dialogOverLimit.dish_qty = surcharge?.qty;

        this.dialogOverLimit.day = day?.name;
        this.dialogOverLimit.limit = surcharge?.surcharge;
        this.dialogOverLimit.show = true;
      }
    }
  },
  async updated() {
    await this.scrollWidthFunc();
    await this.checkerFunc();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.offsetTop);
    window.removeEventListener("scroll", this.stickyMenu);
    window.removeEventListener("scroll", this.scrollBtnShow);
    window.removeEventListener("scroll", this.menuObserver);
    window.removeEventListener("scroll", this.rightBoxPos);
    window.removeEventListener("resize", this.dishMenuWidth);
    window.removeEventListener("resize", this.checkerFunc);
    window.removeEventListener("resize", this.scrollWidthFunc);
  },
  watch: {
    DAY_COMPLETE() {
      if (this.DAY_COMPLETE) {
        document.querySelector(".s-text-caption.hvr-buzz-out").classList.add("animated");
        setTimeout(() => {
          document.querySelector(".s-text-caption.hvr-buzz-out").classList.remove("animated");
        }, 750);
        this.$store.commit("setDayComplete", false);
      }
    },
    GET_TIMER(val) {
      if (val.reload) this.GetOrders();
    },
    GET_INFO_STEP(val) {
      this.infoStep = val;
    },
    async SURCHARGE_CHECKER(val) {
      if (val) {
        await this.GetOrders();
        let day, surcharge;
        this.ORDERS.filter(item => {
          Object.values(item.items).filter(subitem => {
            if (subitem.surcharge) {
              day = item;
              surcharge = subitem;
            }
          });
        });

        if (surcharge && surcharge?.surcharge_id) {
          this.dialogOverLimit.item_id = surcharge?.id;
          this.dialogOverLimit.surcharge_id = surcharge?.surcharge_id;
          this.dialogOverLimit.surcharge_price = surcharge?.surcharge_price;
          this.dialogOverLimit.order_id = day?.id;
          this.dialogOverLimit.dish_price = surcharge?.product?.price;
          this.dialogOverLimit.dish_qty = surcharge?.qty;

          this.dialogOverLimit.day = day?.name;
          this.dialogOverLimit.limit = surcharge?.surcharge;
          this.dialogOverLimit.show = true;
        }
        this.$store.commit("SetSurchargeChecker", false);
      }
    }
  }
};
</script>

<style lang="scss">
.dishes-page {
  color: var(--dark-100);
  position: relative;
  background: var(--bg-main);
  min-height: 1164px;
  .btn-to-top {
    z-index: 2;
    transition: 0.3s;
    position: fixed;
    bottom: 66px;
    right: 378px;
    height: 48px;
    width: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--white-100);
    border-radius: 10px;
    box-shadow: var(--box-shadow-second);
    cursor: pointer;

    @media (max-width: 764px) {
      right: 20px;
      bottom: 75px;
    }

    &:active {
      transform: scale(0.9);
      box-shadow: var(--box-shadow-second);
    }
  }
  .font-regular {
    font-family: "Aeonik Pro Regular", sans-serif;
  }
  #paymentFrame {
    width: 100%;
    height: 500px;
    border: 1px solid #f5f8fa;
    padding: 3px;
  }
  .pref-checkbox {
    border: 1px solid var(--bg-main);
    background: var(--white-100);
    height: 48px;
    padding: 0 12px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    user-select: none;
    cursor: pointer;
    transition: 0.1s;
    margin: 4px;
    text-transform: uppercase;
    color: var(--dark-40);
    &.active {
      color: var(--dark-100);
      background: var(--bg-main);
    }
  }
  .dialog-preference {
    button.grey {
      display: none;
    }
  }
  .checkbox-label {
    .already-preparing {
      display: none;
    }
  }
  .no-search-result {
    background: var(--white-100);
    border-radius: 10px;
    height: 72px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    margin: 35px 20px 0;
    span:last-child {
      color: var(--dark-40);
    }

    p {
      font-size: 12px;
    }

    @media (max-width: 991px) {
      margin-top: 40px;
      padding: 24px 20px 24px;
      height: auto;
    }
  }
  .guest {
    &-info {
      display: none;
      position: absolute;
      bottom: calc(100% + 12px);
      left: -28px;
      z-index: 1;

      &-inner {
        padding: 20px;
        position: relative;
        width: 286px;
        color: var(--bg-white);
        font-size: 14px;
        line-height: 22px;
        background: var(--blue-main);
        border-radius: 10px;

        @media (max-width: 767px) {
          display: none;
        }
        &:after {
          content: url("data:image/svg+xml,%3Csvg width='72' height='12' viewBox='0 0 72 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M37.2 11.1C36.4889 11.6333 35.5111 11.6333 34.8 11.1L20 0H52L37.2 11.1Z' fill='%233CAFE9'/%3E%3C/svg%3E%0A");
          display: block;
          position: absolute;
          top: calc(100% - 4px);
          left: 0;
        }
      }
    }

    .employee-icon:hover + .guest-info {
      display: block;
    }
  }

  .btn-info:hover {
    & > div {
      display: block;
      text-transform: none;

      @media (max-width: 1200px) {
        left: 0;
      }
      @media (max-width: 375px) {
        left: -12px;
      }

      .guest-info-inner:after {
        left: 15px;

        @media (max-width: 1200px) {
          left: -13px;
        }
        @media (max-width: 375px) {
          left: -1px;
        }
      }
    }
  }

  .list-enter-active {
    transition: all 1s ease;
  }
  .list-enter-from {
    opacity: 0;
    transform: translateY(30px);
  }

  .dialog-setting {
    .grey-text {
      color: var(--dark-40);
    }
    span.blue {
      color: var(--blue-main);
    }
    .dialog-header h2 {
      padding-right: 10px;
    }
    .surcharge-text {
      max-width: 540px;
      padding-bottom: 20px;
    }

    .dialog-btns .mr:nth-child(2) + button {
      margin-left: auto;
    }

    .custom-select {
      margin: 0 30px 0 0;

      .selected {
        height: 51px;
      }
    }
  }

  .main {
    display: flex;
  }
  .left-box {
    width: calc(100% - 355px);
  }
  h2.r-title-1 {
    padding: 0 20px;
    margin-top: 82px;
    margin-bottom: 26px;
  }
  .dishes-category-sort-box:not(:first-child) {
    h2.r-title-1 {
      margin-top: 38px;
    }
  }

  .dish-menu-nav {
    position: relative;
    top: 0;
    display: flex;
    max-width: 100%;
    min-height: 48px;
    text-transform: uppercase;
    padding: 0 20px;
    background: var(--bg-main);
    opacity: 0;

    @media (max-width: 1199px) {
      flex-direction: column;
      justify-content: center;
      &.sticky {
        height: 56px;
      }

      .dish-menu-nav-btns.overflowed {
        overflow: hidden;
      }
    }

    &-btns {
      display: flex;
      @media (min-width: 1200px) {
        margin-left: auto;
      }
      @media (max-width: 1199px) {
        margin-top: 10px;
      }
    }

    &.sticky {
      margin-top: 0;
      width: 100%;
      position: fixed;
      z-index: 2;
      box-sizing: border-box;
      background: none;

      &:after {
        content: "";
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        max-height: none;
        height: 48px;
        backdrop-filter: saturate(180%) blur(20px);
        @media (max-width: 1199px) {
          height: 56px;
        }
      }

      a {
        color: var(--dark-100);
      }
    }

    .btn-preferences {
      @media (max-width: 1199px) {
        margin-right: 20px;
      }
    }
    .search-dish {
      width: 145px;
      max-width: 300px;
      margin-right: 8px;
      @media (min-width: 1200px) {
        &.user {
          margin-left: auto;
        }
      }
      &:focus-within {
        width: 300px;
        max-width: calc(100% - 56px);
      }
    }
    .btn-info {
      margin-left: auto;
      margin-right: 8px;
      @media (max-width: 1199px) {
        margin-left: 0;
      }
    }
  }
  .dish-category-list {
    // transition: 0.3s;
    // transition-delay: 0.5s;
    align-items: center;
    display: flex;
    position: relative;
    z-index: 1;
    @media (min-width: 1200px) {
      margin-right: 20px;
    }
    @media (max-width: 1199px) {
      width: 100%;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
    a {
      text-decoration: none;
      white-space: nowrap;
      cursor: pointer;
      color: var(--dark-40);
      &.active {
        color: var(--blue-main);
      }
      &:hover {
        color: var(--blue-main);
      }
    }
    a:not(:first-child) {
      margin-left: 20px;
    }
  }

  .dishes-list-box {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 10px 70px;

    .dish-container {
      margin: 10px;
      margin-bottom: 50px;
    }
  }
  .right-box {
    position: sticky;
    top: 2px;
    flex-direction: column;
    padding: 2px 0;
    transition: 0.5s ease;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    //.dish-day:first-child .remove-dish-btn {
    //  display: none;
    //}
    @media (min-width: 765px) {
      max-height: calc(100vh - 72px);
      overflow: auto;
      &.fullHeight {
        max-height: 100vh;
      }
      &:after {
        content: "";
        display: block;
        position: sticky;
        right: 0;
        bottom: -2px;
        margin-top: -29px;
        height: 29px;
        background: linear-gradient(180deg, rgba(245, 248, 250, 0) 0%, #fff 98.5%);
        border-radius: 10px;
        z-index: 1;
      }
    }

    &.minHeight {
      max-height: 100vh;
    }

    @media (max-width: 1439px) {
      &.minHeight {
        max-height: calc(100vh - 64px);
      }
    }
    @media (max-width: 991px) {
      &.minHeight {
        max-height: calc(100vh - 120px);
      }
    }
  }
  .info-meassage {
    cursor: default;
  }
  .step-text {
    text-transform: none;
    padding-bottom: 20px;
  }
  .step-interactive-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    span {
      height: 4px;
      width: 24px;
      border-radius: 10px;
      background: var(--white-40);
      margin-right: 8px;
      &.only-mob {
        display: none;
      }
      &.active {
        background: var(--white-100);
      }
    }
  }
  .step-btn {
    background: var(--white-100);
    border-radius: 8px;
    height: 48px;
    cursor: pointer;
    text-transform: uppercase;
    color: var(--blue-main);
    padding: 0 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    text-decoration: none;
  }
  .preference-info-btn {
    position: relative;
    .info-meassage {
      z-index: 3;
    }
  }
  .dishes-list-box .info-meassage {
    z-index: 3;

    @media (max-width: 764px) {
      &.step-in-card {
        bottom: -167px !important;
        right: 0 !important;

        .info-arrow {
          left: calc(50% - 2px);
        }
      }
    }
  }
  .confirm-box {
    margin-left: auto;
    display: flex;
    align-items: center;
    span {
      cursor: default;
      margin-bottom: -3px;
    }
  }
  .check-confirm {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--dark-10);
    border-radius: 4px;
    width: 24px;
    height: 24px;
    margin-right: 8px;
    svg {
      opacity: 0;
      width: 18px;
      height: 18px;
      color: var(--blue-main);
      transition: 0.2s;
      visibility: hidden;
    }
    & input[type="checkbox"] {
      display: none;
    }
    input[type="checkbox"]:checked ~ svg {
      visibility: visible;
      opacity: 1;
    }
  }
  .preference-text {
    margin-top: 0px;
    font-family: "Aeonik Pro", sans-serif;
  }
  .products-alergie-list {
    padding-top: 36px;
    .products-title {
      text-transform: uppercase;
    }
    .checkbox-box {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 36px;
    }
    & > .checkbox-box {
      margin-bottom: 0;
      flex-direction: column;
    }
    .checkbox {
      margin: 4px;
      text-transform: uppercase;
      color: var(--dark-40);
    }
    input[type="checkbox"]:checked + .checkbox {
      color: var(--dark-100);
    }
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .card-title {
      text-transform: uppercase;
    }
    .card-content {
      margin-top: 8px;
      display: flex;
      flex-wrap: wrap;
    }
    .btn-add {
      margin: 4px;
      font-weight: 500;
      font-size: 12px;
      line-height: 24px;
      letter-spacing: 0.04em;
      color: var(--dark-40);
      svg {
        color: var(--dark-40);
      }
      &:hover {
        color: var(--white-100);
        svg {
          color: var(--white-100);
        }
      }
    }
  }
  .swich-mode-dish {
    position: relative;
    .info-meassage {
      display: none;
    }
  }
  .swich-mode-dish-btn {
    cursor: pointer;
    min-width: 48px;
    height: 48px;
    display: none;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    margin-right: 8px;
    background: var(--blue-light);
  }
  .modal-right-box {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
  }
  .mob-footer-btn {
    display: none;
  }
  .global-dishes-container {
    margin-top: 8px;
  }
  .dish-category-list {
    overflow-x: auto;
  }
  @media (max-width: 1350px) {
    .dish-menu-nav {
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .preference-info-btn {
      height: 48px;
    }
    .search-dish {
      min-width: 130px;
      svg {
        min-width: 16px;
      }
      &:focus-within {
        input {
          padding-right: 35px;
        }
      }
    }
    .dish-menu-nav:focus-within {
      .dish-category-list {
        transition-delay: 0s;
        top: auto;
        opacity: 1;
        visibility: visible;
      }
    }
  }
  @media (max-width: 1024px) {
    .user-box .info-meassage {
      right: auto !important;
      left: -57px;
      top: 172% !important;
      .info-arrow.tr {
        right: calc(66% - 12px);
      }
    }
    .dish-menu-nav .search-dish {
      margin-right: 8px;
    }
    .left-box .dishes-category-sort-box h2 {
      margin-top: 30px;
    }
    footer {
      .text {
        padding-right: 20px;
      }
      .contacts {
        svg {
          min-width: 16px;
        }
      }
    }
  }
  @media (max-width: 764px) {
    .step-interactive-box span.only-mob {
      display: block;
    }

    .right-box {
      padding-top: 0;
      width: 100%;
      max-height: calc(100% - 130px);
      flex-shrink: 0;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: auto;
    }

    .dish-day {
      width: 100%;
      margin: 0 0 4px;
    }

    .main {
      padding-bottom: 206px;
    }
    .preference-info-btn {
      .info-meassage {
        right: -119px !important;
      }
      .info-arrow {
        left: 48%;
      }
    }
    .dish-menu-nav .search-dish {
      width: 48px;
      min-width: 48px;
    }
    .swich-mode-dish .info-meassage {
      display: block;
      left: auto !important;
      right: -84%;

      .info-arrow {
        left: 68%;
      }
    }
    .swich-mode-dish-btn {
      margin-right: 8px;
      display: flex;
    }
    .dish-menu-nav .btn-preferences {
      margin-right: 8px;
      width: 48px;
      span {
        display: none;
      }
      svg {
        margin-right: 0;
        margin-left: 5px;
        margin-top: 1px;
      }
    }
    .dish-card {
      max-width: 320px;
      min-height: auto;
    }
    .dish-img {
      max-width: 100%;
    }
    .modal-right-box {
      transition: padding 0.5s, visibility 0.2s 0.3s, opacity 0.2s 0.3s;
      z-index: 50;
      padding-top: 100%;
      background: var(--dark-40);
      height: 100vh;
      overflow-y: scroll;
      position: fixed;
      bottom: 0;
      width: 100%;
      align-items: center;
      visibility: hidden;
      opacity: 0;
      &.active {
        justify-content: flex-end;
        width: 100%;
        padding: 0;
        transition: padding 0.5s, visibility 0s, opacity 0s;
        opacity: 1;
        visibility: visible;
        touch-action: none;
        -ms-touch-action: none;
      }
    }
    .modal-drag-btn {
      position: relative;
      width: 100%;
      min-height: 130px;
      height: 100%;

      &:before {
        position: absolute;
        left: 50%;
        bottom: 8px;
        transform: translateX(-50%);
        content: "";
        width: 40px;
        background: #fff;
        border: 2px solid #fff;
        border-radius: 8px;
      }
    }

    .left-box {
      width: 100%;
    }
    footer {
      margin-bottom: 50px;
      flex-direction: column-reverse;
      .contacts {
        margin-bottom: 20px;
      }
    }
    .dialog-btns {
      flex-wrap: wrap;
    }
    .confirm-box {
      width: 100%;
      height: 48px;
      order: -1;
      margin-bottom: 20px;
    }
    .micro-mode {
      .ingredients-toggler {
        display: none;
      }
      .dishes-list-box {
        justify-content: flex-start;
        overflow: hidden;
      }
      .dishes-category-sort-box {
        h2.r-title-1 {
          margin-bottom: 40px;
        }
      }
      .dish-container {
        padding-top: 35px;
        margin: 0 6px 10px !important;
        width: calc(50% - 12px);

        &:nth-child(even) .info-meassage {
          left: auto !important;
          right: -17px !important;

          .info-arrow {
            left: auto !important;
            right: 19px !important;
          }
        }
      }

      .dish-card {
        padding: 12px;
        padding-top: 86px;
        position: relative;
        width: auto;
        max-width: none;
        min-height: 200px;
        height: 100%;
        .price {
          position: absolute;
          bottom: 12px;
          right: 12px;
          justify-content: flex-end;
          width: 100%;
          text-align: right;
        }
        .title {
          font-weight: 500;
          font-size: 12px;
          line-height: 16px;
          letter-spacing: 0.04em;
        }
      }
      .dish-btns {
        display: none;
      }
      .main-description {
        margin-left: 0;
        margin-right: auto;
        position: relative;
      }
      .dish-img {
        transform: translateX(-50%);
        left: 50%;
        width: 150px;
        top: -35px;
      }
      .dish-description {
        margin-top: 22px;
        margin-bottom: 0;
        flex-direction: column;
        .price {
          margin-left: 0;
          padding-left: 0;
          align-self: flex-start;
        }
      }
    }

    .mob-footer-btn {
      z-index: 3;
      transition: 0;
      cursor: pointer;
      display: flex;
      position: fixed;
      bottom: -70px;
      height: 70px;
      padding-bottom: 10px;
      background: var(--blue-main);
      color: var(--white-100);
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: 8px 8px 0px 0px;
      &.active {
        bottom: -4px;
        transition: 0.4s 0.7s cubic-bezier(0.47, 1.64, 0.41, 0.8);
      }
    }
    .dishes-list-box {
      justify-content: center;
    }
  }

  @media (max-width: 375px) {
    .user-box .info-meassage {
      left: -80px;

      .info-arrow.tr {
        right: calc(66% - 44px);
      }
    }
    .is-allergie .info-meassage {
      width: max-content;
    }
    .dishes-list-box .dish-container {
      margin: 6px;
    }
    footer {
      .contacts {
        flex-wrap: wrap;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .cards-select-wrapper {
    margin-top: 30px;
    display: flex;

    & > div {
      padding-bottom: 0;
      max-width: 270px;
      min-width: auto;
    }

    .main {
      height: 47px;
    }
  }

  footer {
    @media (min-width: 765px) {
      width: calc(100% - 355px);
    }
  }

  .cards-modal {
    .cards-select-wrapper {
      margin-top: 0;
      width: 100%;
    }

    .custom-select {
      margin: 0 0 0 auto;
      width: 100%;
    }

    @media (max-width: 767px) {
      .mr {
        margin-right: 20px;
      }
      .dialog-btns {
        flex-direction: row;
      }

      .custom-select,
      .m-text-des {
        margin: 20px 0 0 0;
      }
    }
  }
  .micro-price {
    display: none;
  }
  .kkal-box.micro {
    display: none;
  }
  @media (max-width: 764px) {
    .micro-mode {
      .title {
        margin-bottom: 10px;
      }
      .icons-box {
        position: absolute;
        bottom: 0;
        right: 0;
      }
      .kkal-box.micro {
        margin-bottom: 10px;
        display: flex;
      }
      .kkal-box:not(.micro) {
        display: none;
      }
      .original-price {
        display: none;
      }
      .kkal-box {
        flex-wrap: wrap;
      }
      .kkal-cell {
        margin-bottom: 3px;
      }
      .price.micro-price {
        margin-bottom: 3px;
        width: auto;
        height: 24px;
        display: block;
        position: static;
        text-align: left;
      }
    }
  }
}
body {
  background: var(--bg-main);
}
body.ios .modal-right-box.active .right-box {
  max-height: calc(100% - 130px);
}
.dialog-preference .dialog-btns {
  margin-top: 0;
}
</style>
